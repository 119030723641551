<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo_icon.png" />
    <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
    <div class="box">
      <div class="font">
        <p>人的一生，做好一件事不难</p>
        <p>难的是一生坚持做好一件事</p>
        <p>专注和坚持</p>
        <p>铸就了很多职业的魅力</p>
        <p>外科医生便是其中一种</p>
        <p style="margin-top: 20px;">在他们身上</p>
        <p>“几十年如一日”不是一句虚词</p>
        <p>而是一台台手术、一次次缝合的诠释</p>
        <p class="bold">人生一术，精彩无数</p>
        <p>带我们走近顶尖术者的“一术人生”</p>
      </div>
      <div class="tip">
        <span class="line"></span>
        <span class="tipFont">点击下方观看视频</span>
        <span class="line"></span>
      </div>
      <div class="videoBox">
        <video
          id="video"
          style="width: 100%; height: 100%;"
          preload="auto"
          playsinline=""
          webkit-playsinline=""
          x5-playsinline=""
        ></video>
      </div>
      <div class="font">
        <p>术式规范，在他们手中代代沿袭 </p>
        <p>医者仁心，在他们身上薪火相传</p>
        <p class="btn" @click="$router.push('/home')">在这里，打开无限术式精彩<img class="icon" src="@/assets/images/more_icon.png" /></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import utils from "../../common/utils/utils";
export default {
  name: 'OperativeMonthIntroduce',
  data() {
    return {
      player: null,
      videoInfo: null,
      kpng: require("../../assets/images/k.png"),
    }
  },
  computed: {
    ...mapState(["userInfo", "Openid"]),
  },
  created() {
    this.initShare();
  },
  mounted() {
    this.getVideo();
  },
  methods: {
    // 初始化播放器
    initVideo() {
      // if (!this.player) {
      //   this.player = this.TCPlayer('video', {
      //     loop: true,
      //     licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
      //   }).player;
      //   this.player.src('https://1500012405.vod2.myqcloud.com/a2881783vodtranssh1500012405/5c83573e243791580953736593/v.f100030.mp4');
      // }
      let Options = {};
      if(this.videoInfo && this.videoInfo.fileid) {
        Options = {
          loop: true,
          fileID: this.videoInfo.fileid,
          appID: this.videoInfo.appid,
          psign: this.videoInfo.psign,
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer('video', Options, {
          app_Play_Id: this.videoInfo.app_Play_Id,
          app_Id: 'wcwechat',
          user_Id: this.videoInfo.user_Id,
          video_Id: 0
        },{
          Id: this.userInfo.Id,
          RealName: this.userInfo.RealName,
          HospitalTitle: this.userInfo.HospitalTitle,
          PositionalName: this.userInfo.PositionalName,
          SubjectName: this.userInfo.SubjectName,
          Phone: this.userInfo.Phone
        }, this.videoInfo.Name, this.videoInfo.videoSize, this.videoInfo.fileid).player;
      } else {
        Options = {
          loop: true,
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer("video", Options).player;
        this.player.src('https://1500012405.vod2.myqcloud.com/a2881783vodtranssh1500012405/5c83573e243791580953736593/v.f100030.mp4');
      }
    },
    getVideo() {
      this.$axios.get('/Api/api/Web/Article/GetUI?vfid=243791580953736593').then(res => {
        this.videoInfo = res.Data;
        this.initVideo();
      })
    },
    initShare() {
      let that = this;
      const entryLink = utils.getEntryLink();
      that.$axios
        .get("/Api/Api/web/WechatHelper/GetWechat?backUrl=" + entryLink)
        .then((res) => {
          wx.config({
            debug: false,
            appId: res.Data.AppId,
            timestamp: res.Data.TimesTamp,
            nonceStr: res.Data.NonceStr,
            signature: res.Data.She1Str,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          });
          wx.checkJsApi({
            jsApiList: [
              // 所有要调用的 API 都要加到这个列表中
              "updateAppMessageShareData", // 分享到朋友圈接口
              "updateTimelineShareData", //  分享到朋友接口
            ],
            success: async function (res) {
              wx.ready(() => {
                wx.updateAppMessageShareData({
                  title: '《人生一术》先导片',
                  link: process.env.VUE_APP_BASE_URL+'/operativeMonthIntroduce',
                  desc: '讲述外科医生手术故事，见证缝合技术创新发展',
                  imgUrl: "https://wcwechat.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
                wx.updateTimelineShareData({
                  title: '《人生一术》先导片',
                  link: process.env.VUE_APP_BASE_URL+'/operativeMonthIntroduce',
                  desc: '讲述外科医生手术故事，见证缝合技术创新发展',
                  imgUrl: "https://wcwechat.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
              });
            },
          });
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  min-height: 100%;
  background-image: url('../../assets/images/rsys_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 16px;
  box-sizing: border-box;
  .logo {
    width: 100px;
    margin: 20px 0;
  }
  .goBack {
    position: absolute;
    left: 15px;
    top: 30px;
    width: 30px;
    height: 30px;
  }
  .box {
    display: block;
    min-height: 85%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 25px 15px;
    box-sizing: border-box;
  }
  .font {
    p {
      color: #000000;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
    }
    .bold {
      font-weight: bold;
      color: #CA001B;
      font-size: 14px;
    }
    .btn {
      width: 70%;
      height: 26px;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: #FFFFFF;
      background-color: #CA001B;
      font-size: 14px;
      margin: 10px auto 0;
      .icon {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  .tip {
    display: flex;
    text-align: center;
    line-height: 32px;
    margin-top: 10px;
    .line {
      flex: 1;
      height: 1px;
      border-top: 1px solid rgba(202, 0, 27, 0.50);
      margin-top: 15px;
    }
    .tipFont {
      font-size: 10px;
      color: #CA001B;
      padding: 0 10px;
      opacity: 0.5;
    }
  }
  .videoBox {
    height: 176.5px;
    margin: 0 0 15px;
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>